<template>
    <div v-if="client && portfolio" class="tl">
        <vxe-toolbar class="tl-header tl__mod-header">
            <template v-slot:buttons>
                <div class="tl__mod-header-title">Custodian Accounts</div>
                <div class="tl__mod-header-subtitle">
                    <ClientHeaderLink :client="client" />
                    <PortfolioHeaderLink :portfolio="portfolio" :clientid="client.id" />
                </div>
            </template>
            <template v-slot:tools>
                <el-button type="primary" @click="add_event">Add account</el-button>
            </template>
        </vxe-toolbar>

        <vxe-grid
            round
            stripe
            :max-height="table_height"
            show-overflow
            highlight-hover-row
            highlight-current-row
            size="mini"
            class="tl-grid tl__mod-grid"
            header-row-class-name="tl-grid-header"
            ref="DataGrid"
            auto-resize
            :data="list"
            :columns="columns">
                <template v-slot:col_header="{ column }">
                    <div class="tl-grid-header-title">{{column.title}}</div>
                </template>

                <template v-slot:custodian_default="{ row }">
                    {{define_custodian(row.custodian_id)}}
                </template>

                <template v-slot:disabled_default="{ row }">
                    <el-tag type="danger" v-if="row.inactive" size="mini">inactive</el-tag>
                </template>

                <template v-slot:varmargin_default="{ row }">
                    <el-tag type="info" v-if="row.varmargin" size="mini">variation margin</el-tag>
                </template>

                <template v-slot:oper_default="{ row }">
                    <el-button
                        type="text"
                        class="tl-edit_button"
                        @click="edit_event(row)"
                        size="mini">
                        <svg-icon icon-class="edit" />
                    </el-button>
                </template>
        </vxe-grid>

        <vxe-modal
            v-model="show_edit"
            :title="formdata._id ? 'Edit Custodian Account' : 'Add New Custodian Account'"
            :width="700"
            destroy-on-close
            :dblclickZoom="false"
            size="mini"
            className="tl-modal">

            <el-form
                label-position="top"
                :model="formdata"
                size="small"
                ref="Editor"
                :rules="rules"
                autocomplete="off"
                class="tl-form">

                <div class="tl-form-body" v-loading="loading">
                    <el-form-item label="Account Name" prop="name">
                        <el-input v-model="formdata.name" />
                    </el-form-item>
                    <el-form-item label="Custodian" prop="custodian_id">
                        <el-select v-model="formdata.custodian_id">
                            <el-option
                                v-for="item in custodians"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Type" prop="type">
                        <el-select v-model="formdata.type" placeholder="Type">
                            <el-option label="Cash" value="cash"></el-option>
                            <el-option label="Custody" value="custody"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Use for variation margin" prop="varmargin">
                        <el-checkbox v-model="formdata.varmargin" :disabled="disabled_varmargin"></el-checkbox>
                    </el-form-item>
                </div>
                
                <div class="tl-form-buttons">
                    <!-- <el-button type="danger" @click="remove_event(formdata)" v-if="formdata._id" :disabled="has_balance">Delete</el-button> -->
                    <el-button type="danger" plain @click="deactivate_event(formdata)" v-if="formdata._id && !formdata.inactive" :disabled="has_balance || loading">
                        Deactivate
                    </el-button>
                    <el-button type="success" plain @click="activate_event(formdata)" v-if="formdata._id && formdata.inactive" :disabled="has_balance || loading">
                        Activate
                    </el-button>
                    <div class="spacer" />
                    <el-button @click="show_edit = false">Cancel</el-button>
                    <el-button type="primary" @click="submit_event" :disabled="loading">Save</el-button>
                </div>
            </el-form>

        </vxe-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import $ from 'jquery'

import ClientHeaderLink from '@/components/PageComponents/ClientHeaderLink'
import PortfolioHeaderLink from '@/components/PageComponents/PortfolioHeaderLink'

export default {
    name: 'accounts',

    components: {
        ClientHeaderLink,
        PortfolioHeaderLink,
    },

    props: {
        clientid: {
            required: true,
        },
        portfolioid: {
            required: true,
        },
    },

    data(){
        return {
            client    : undefined,
            portfolio : undefined,
            loading   : false,
            list      : [],
            columns   : [
                {
                    field    : 'name',
                    title    : 'Account Name',
                    minWidth : 100,
                    slots    : { header: 'col_header' }
                },{
                    field    : 'custodian_id',
                    title    : 'Custodian',
                    minWidth : 100,
                    slots    : { default: 'custodian_default', header: 'col_header' }
                },{
                    field    : 'type',
                    title    : 'Type',
                    width : 100,
                    slots    : { header: 'col_header' }
                },{
                    width : 120,
                    align :'center',
                    slots : { default: 'varmargin_default' }
                },{
                    width : 100,
                    align :'center',
                    slots : { default: 'disabled_default' }
                },{
                    width : 50,
                    align :'center',
                    slots : { default: 'oper_default' }
                },
            ],
            show_edit : false,
            formdata  : {},
            rules     : {
                name         : [{ required: true, message: 'Please input Account name', trigger: 'blur' }],
                custodian_id : [{ required: true, message: 'Please select Custodian', trigger: 'blur' }],
            },
            has_balance: false,
        }
    },

    computed: {
        ...mapState({
            custodians : state => state.dictionaryCustodians.options,
        }),
        table_height(){
            return $(window).height() - 232
        },
        disabled_varmargin(){
            let exist_account = this.list.find(account => account.varmargin == true && account.disabled == false)
            if (!exist_account) return false;
            if (this.formdata._id && this.formdata._id === exist_account._id)
                return false;
            return true
        }
    },

    methods: {
        get_list(){
            this.loading = true
            this.$store.dispatch('portfolioAccounts/getList', {
                portfolio_id : this.portfolio._id,
            }).then((response) => {
                this.list = response
                this.loading = false
            })
        },

        define_custodian(id){
            let option = this.custodians.find(option => option.value === id)
            if (option) return option.label;
            return ''
        },

        add_event(){
            this.show_edit = true
            this.has_balance = false
            this.formdata = Object.assign({}, { portfolio_id: this.portfolio._id });
        },

        edit_event(row){
            this.show_edit = true
            this.has_balance = false
            this.loading = true
            this.formdata = Object.assign({}, row);

            let balance_params = {
                date      : this.$moment().format('YYYY-MM-DD'),
                mode      : 'trade',
                client_id : this.client._id,
                account   : this.formdata._id
            }
            this.$store.dispatch('portfolioAccounts/getBalance', balance_params).then((trade_response) => {
                if (trade_response.length) {
                    this.loading = false
                    this.has_balance = true
                }
                else {
                    balance_params.mode = 'value'
                    this.$store.dispatch('portfolioAccounts/getBalance', balance_params).then((value_response) => {
                        if (value_response.length) 
                            this.has_balance = true;
                        this.loading = false
                    })
                }
            })
        },
        remove_event(){

        },
        deactivate_event(){
            this.$set(this.formdata, 'inactive', true)
            this.submit_event()
        },
        activate_event(){
            this.$set(this.formdata, 'inactive', false)
            this.submit_event()
        },
        submit_event(){
            let $this = this;
            $this.$refs.Editor.validate((valid) => {
                if (valid) {
                    $this.$refs.Editor.clearValidate();
                    $this.show_edit = false;
                    if ($this.formdata._id) {
                        $this.$store.dispatch('portfolioAccounts/updateItem', $this.formdata).then(() => {
                            $this.get_list();
                        });
                    }
                    else {
                        $this.$store.dispatch('portfolioAccounts/addItem', $this.formdata).then(() => {
                            $this.get_list();
                        });
                    }
                } else {
                    return false;
                }
            });
        }
    },

    mounted(){
        if (this.clientid && this.portfolioid) {
            this.$store.dispatch('clients/getClient', this.clientid).then((client_response) => {
                this.client = client_response
                this.$store.dispatch('portfolio/getPortfolio', this.portfolioid).then((portfolio_response) => {
                    this.portfolio = portfolio_response

                    this.$emit('tab-title-change', 'Accounts in ' + this.portfolio.name + ' for ' + this.client.first_name + ' ' + this.client.last_name)

                    this.get_list()
                    this.$store.dispatch('dictionaryCustodians/getOptions');
                })
            })
        }
    }
}
</script>
